import React, { useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import '../styles/MapComponent.css';

function getCategoryIconUrl(category, visited, isSelected) {
  if (isSelected && visited) return 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png';
  if (isSelected && !visited) return 'http://maps.google.com/mapfiles/ms/icons/purple.png';

  if (visited) {
    switch(category) {
      case 'mustgo': return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
      case 'luxury': return 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
      case 'healthy': return 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      default: return 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    }
  } else {
    switch(category) {
      case 'mustgo': return 'http://maps.google.com/mapfiles/ms/icons/red.png'; 
      case 'luxury': return 'http://maps.google.com/mapfiles/ms/icons/yellow.png';
      case 'healthy': return 'http://maps.google.com/mapfiles/ms/icons/green.png';
      default: return 'http://maps.google.com/mapfiles/ms/icons/blue.png';
    }
  }
}

function MapComponent({ places, onMarkerClick, selectedPlaceId }) {
  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const center = { lat: 1.3521, lng: 103.8198 };
  const mapCenter = places.length > 0 ? places[0].location : center;

  useEffect(() => {
    if (selectedPlaceId && mapRef.current) {
      const place = places.find(p => p.id === selectedPlaceId);
      if (place && place.location) {
        mapRef.current.panTo(place.location);
      }
    }
  }, [selectedPlaceId, places]);

  return (
    <GoogleMap
      mapContainerClassName="map-container"
      center={mapCenter}
      zoom={12}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ 
        fullscreenControl: false, 
        mapTypeControl: false, 
        mapTypeId: 'roadmap',
        streetViewControl: false
      }}
    >
      {places.map((place) => {
        const isSelected = place.id === selectedPlaceId;
        const iconUrl = getCategoryIconUrl(place.category, place.visited, isSelected);

        return (
          <Marker
            key={place.id}
            position={place.location}
            title={place.name}
            icon={iconUrl}
            onClick={() => onMarkerClick(place.id)}
          />
        );
      })}
    </GoogleMap>
  );
}

export default MapComponent;
