import React, { useEffect } from 'react';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';
import PlaceCard from './PlaceCard';
import '../styles/PlaceList.css';

function PlaceList({ 
  places, selectedPlaceId, onPlaceClick, onDeletePlace, onEditPlace, onVisitedIconClick,
  showMustGo, setShowMustGo,
  showLuxury, setShowLuxury,
  showHealthy, setShowHealthy,
  showOthers, setShowOthers,
  showVisited, setShowVisited,
  mustGoExpanded, setMustGoExpanded,
  luxuryExpanded, setLuxuryExpanded,
  healthyExpanded, setHealthyExpanded,
  othersExpanded, setOthersExpanded,
  visitedExpanded, setVisitedExpanded
}) {

  const visitedPlaces = places.filter(p => p.visited);
  const mustGoPlaces = places.filter(p => !p.visited && p.category==='mustgo');
  const luxuryPlaces = places.filter(p => !p.visited && p.category==='luxury');
  const healthyPlaces = places.filter(p => !p.visited && p.category==='healthy');
  const othersPlaces = places.filter(p => !p.visited && (p.category===null || p.category===undefined));

  useEffect(() => {
    if (selectedPlaceId) {
      const el = document.getElementById(`place-${selectedPlaceId}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedPlaceId]);

  const renderPlaceCard = (place) => (
    <PlaceCard
      key={place.id}
      place={place}
      selectedPlaceId={selectedPlaceId}
      onPlaceClick={onPlaceClick}
      onDeletePlace={onDeletePlace}
      onEditPlace={onEditPlace}
      onVisitedIconClick={onVisitedIconClick}
      isFocusedView={false}
    />
  );

  const pastelRed = '#ffe5e7';
  const pastelGold = '#fff9e5';
  const pastelGreen = '#eaffed';
  const pastelBlue = '#e6f0ff';
  const pastelGrey = '#f7f7f7';

  const HeaderWithCheckbox = ({expanded, setExpanded, label, color, showValue, setShowValue, children}) => (
    <>
      <div className="section-header" style={{background:color}} onClick={() => setExpanded(!expanded)}>
        {expanded ? <IoChevronDownOutline /> : <IoChevronForwardOutline />}
        <span>{label}</span>
        <span style={{marginLeft:'auto', display:'flex', alignItems:'center', gap:'5px'}} onClick={(e) => e.stopPropagation()}>
          <span style={{fontSize:'12px',color:'#333'}}>Show on map:</span>
          <input 
            type="checkbox" 
            checked={showValue} 
            onChange={(e) => setShowValue(e.target.checked)}
            title={`Toggle display of ${label} on map`}
          />
        </span>
      </div>
      {expanded && (
        <div className="places-list">
          {children}
        </div>
      )}
    </>
  );

  return (
    <div className="places-list-wrapper">
      <HeaderWithCheckbox
        expanded={mustGoExpanded}
        setExpanded={setMustGoExpanded}
        label="Must Go"
        color={pastelRed}
        showValue={showMustGo}
        setShowValue={setShowMustGo}
      >
        {mustGoPlaces.length > 0 ? mustGoPlaces.map(renderPlaceCard) : <p>No must go places</p>}
      </HeaderWithCheckbox>

      <HeaderWithCheckbox
        expanded={luxuryExpanded}
        setExpanded={setLuxuryExpanded}
        label="Luxury"
        color={pastelGold}
        showValue={showLuxury}
        setShowValue={setShowLuxury}
      >
        {luxuryPlaces.length > 0 ? luxuryPlaces.map(renderPlaceCard) : <p>No luxury places</p>}
      </HeaderWithCheckbox>

      <HeaderWithCheckbox
        expanded={healthyExpanded}
        setExpanded={setHealthyExpanded}
        label="Healthy"
        color={pastelGreen}
        showValue={showHealthy}
        setShowValue={setShowHealthy}
      >
        {healthyPlaces.length > 0 ? healthyPlaces.map(renderPlaceCard) : <p>No healthy places</p>}
      </HeaderWithCheckbox>

      <HeaderWithCheckbox
        expanded={othersExpanded}
        setExpanded={setOthersExpanded}
        label="Others"
        color={pastelBlue}
        showValue={showOthers}
        setShowValue={setShowOthers}
      >
        {othersPlaces.length > 0 ? othersPlaces.map(renderPlaceCard) : <p>No other places</p>}
      </HeaderWithCheckbox>

      <HeaderWithCheckbox
        expanded={visitedExpanded}
        setExpanded={setVisitedExpanded}
        label="Visited"
        color={pastelGrey}
        showValue={showVisited}
        setShowValue={setShowVisited}
      >
        {visitedPlaces.length > 0 ? visitedPlaces.map(renderPlaceCard) : <p>No visited places</p>}
      </HeaderWithCheckbox>
    </div>
  );
}

PlaceList.PlaceCard = PlaceCard;

export default PlaceList;
