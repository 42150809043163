import React, { useState, useEffect, useRef } from 'react';
import { IoGlobeOutline, IoTrashOutline } from 'react-icons/io5';
import { FaEdit, FaRegCircle, FaCheckCircle, FaRegFileAlt } from 'react-icons/fa';
import '../styles/PlaceCard.css';

// Example: Firestore imports (adjust to your own Firebase setup)
// import { doc, updateDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';

function PlaceCard({
  place,
  selectedPlaceId,
  onPlaceClick,
  onDeletePlace,
  onEditPlace,
  onVisitedIconClick,
  isFocusedView,
}) {
  const isSelected = place.id === selectedPlaceId ? 'selected' : '';
  const [showNotes, setShowNotes] = useState(false);
  const [photoURL, setPhotoURL] = useState(place.photoURL);

  const notesIconRef = useRef(null);
  const notesPopupRef = useRef(null);

  // Only create a single placesService instance. We'll do it outside the component scope:
  let placesServiceRef = useRef(null);

  // Initialize the placesService once
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      !placesServiceRef.current
    ) {
      const dummyMap = document.createElement('div');
      placesServiceRef.current = new window.google.maps.places.PlacesService(dummyMap);
    }
  }, []);

  // Check if the URL is stale (older than 24 hours) and regenerate if needed
  useEffect(() => {
    async function checkAndRegeneratePhotoURL() {
      if (!place.placeId) return; // We can't re-fetch without a placeId
      const photoGeneratedAt = place.photoGeneratedAt || 0;
      const now = Date.now();
      // If older than 24 hours or missing, it's stale
      const isStale = !photoGeneratedAt || (now - photoGeneratedAt > 24 * 60 * 60 * 1000);

      if (isStale) {
        try {
          const newPhotoURL = await getNewPhotoURLFromGoogle(place.placeId, placesServiceRef.current);
          if (newPhotoURL) {
            setPhotoURL(newPhotoURL);
            // Update Firestore or your DB
            await updatePlaceInFirebaseDoc(place.id, {
              photoURL: newPhotoURL,
              photoGeneratedAt: Date.now(),
            });
          }
        } catch (err) {
          console.error('Error regenerating ephemeral URL:', err);
        }
      } else {
        // Not stale, just use the existing photoURL
        setPhotoURL(place.photoURL);
      }
    }

    checkAndRegeneratePhotoURL();
  }, [place]);

  // Firestore update function (adjust to your setup)
  async function updatePlaceInFirebaseDoc(placeDocId, data) {
    // Example if using Firestore:
    /*
    const docRef = doc(db, "places", placeDocId);
    await updateDoc(docRef, data);
    */
    console.log(`(Mock) Updating doc ${placeDocId} with:`, data);
  }

  // Utility to fetch a new ephemeral URL from Google Places
  function getNewPhotoURLFromGoogle(placeId, placesService) {
    return new Promise((resolve, reject) => {
      if (!placesService) {
        return reject(new Error('placesService not initialized'));
      }
      placesService.getDetails({ placeId }, (detail, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          detail.photos &&
          detail.photos.length > 0
        ) {
          const newURL = detail.photos[0].getUrl({ maxWidth: 400, maxHeight: 400 });
          resolve(newURL);
        } else {
          reject(new Error('No photos available or request failed.'));
        }
      });
    });
  }

  const handleCardClick = () => {
    if (onPlaceClick) {
      onPlaceClick(place.id);
    }
  };

  const handleWebsiteClick = (e, url) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  const handleDeleteClick = (e, placeId) => {
    e.stopPropagation();
    onDeletePlace(placeId);
  };

  const handleEditClick = (e, placeId) => {
    e.stopPropagation();
    onEditPlace(placeId);
  };

  const handleVisitedClick = (e) => {
    e.stopPropagation();
    onVisitedIconClick(place);
  };

  const isMobileDevice = () => window.innerWidth <= 768;

  const handleNotesIconClick = (e) => {
    e.stopPropagation();
    setShowNotes((prev) => !prev);
  };

  const handleMouseEnterNotes = () => {
    if (!isMobileDevice()) {
      setShowNotes(true);
    }
  };

  const handleMouseLeaveNotes = () => {
    if (!isMobileDevice()) {
      setShowNotes(false);
    }
  };

  useEffect(() => {
    if (!showNotes) return;
    const handleOutsideClick = (event) => {
      const path = event.composedPath();
      const notesIconEl = notesIconRef.current;
      const notesPopupEl = notesPopupRef.current;

      if (notesIconEl && notesPopupEl) {
        if (!path.includes(notesIconEl) && !path.includes(notesPopupEl)) {
          setShowNotes(false);
        }
      } else if (notesIconEl && !path.includes(notesIconEl)) {
        setShowNotes(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showNotes]);

  const linkify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const formatText = (text) => {
    if (!text) return '';
    return linkify(text);
  };

  let displayRating = null;
  let displayWebsiteIcon = place.website ? (
    <span
      className="web-icon"
      title="Open website"
      onClick={(e) => handleWebsiteClick(e, place.website)}
    >
      <IoGlobeOutline />
    </span>
  ) : null;

  let notesIcon = null;
  if (place.visited && place.visitedNotes && place.visitedNotes.trim() !== '') {
    notesIcon = (
      <span
        className="notes-icon"
        title="View visit notes"
        onClick={handleNotesIconClick}
        onMouseEnter={handleMouseEnterNotes}
        onMouseLeave={handleMouseLeaveNotes}
        style={{ position: 'relative' }}
        ref={notesIconRef}
      >
        <FaRegFileAlt />
        {showNotes && (
          <div className="notes-popup" ref={notesPopupRef}>
            <p dangerouslySetInnerHTML={{ __html: formatText(place.visitedNotes) }}></p>
          </div>
        )}
      </span>
    );
  }

  if (place.visited) {
    if (place.visitedRating != null) {
      displayRating = (
        <div className="visited-rating">{place.visitedRating} ★</div>
      );
    } else {
      displayRating = <div className="visited-rating">Visited</div>;
    }
  } else {
    if (place.stars) {
      displayRating = (
        <div className="place-rating">{place.stars} ★</div>
      );
    }
  }

  const visitedIcon = place.visited ? <FaCheckCircle /> : <FaRegCircle />;

  const categoryLabel = (cat) => {
    switch (cat) {
      case 'mustgo':
        return 'Must go';
      case 'luxury':
        return 'Luxury';
      case 'healthy':
        return 'Healthy';
      default:
        return 'Others';
    }
  };

  let categoryBadge = null;
  if (place.visited) {
    categoryBadge = (
      <span className="category-badge">[{categoryLabel(place.category)}]</span>
    );
  }

  return (
    <div
      className={`place-card ${isSelected}`}
      id={`place-${place.id}`}
      onClick={handleCardClick}
      style={{ overflow: 'visible' }}
    >
      <div className="place-card-inner">
        <div className="place-image-container">
          {photoURL ? (
            <img src={photoURL} alt={place.name} className="place-image" />
          ) : (
            <div className="no-image">No image</div>
          )}
        </div>
        <div className="place-details-container">
          <div className="place-details-header">
            <div className="place-name-wrapper">
              <h3 className="place-name">{place.name}</h3>
              {displayWebsiteIcon}
              {notesIcon}
            </div>
          </div>
          {categoryBadge && (
            <p style={{ marginTop: '5px', fontSize: '12px', color: '#555' }}>
              {categoryBadge}
            </p>
          )}
          {place.remarks && place.remarks.trim() !== '' && (
            <p
              className="place-remarks"
              dangerouslySetInnerHTML={{ __html: formatText(place.remarks) }}
            ></p>
          )}
        </div>
      </div>

      {displayRating}

      <div className="visited-icon-container" onClick={handleVisitedClick}>
        {visitedIcon}
      </div>
      <div className="edit-icon-container" onClick={(e) => handleEditClick(e, place.id)}>
        <FaEdit />
      </div>
      <div className="delete-icon-container" onClick={(e) => handleDeleteClick(e, place.id)}>
        <IoTrashOutline />
      </div>
    </div>
  );
}

export default PlaceCard;
